<template>
	<div class="label-list">
		<el-form :inline="true" class="form-content">

			<el-form-item>

				<el-input placeholder="请输入关键字查询" v-model="search" class="input-with-select" style="width: 400px">
					<el-select style="width: 120px;" v-model="select" slot="prepend" placeholder="请选择">

						<el-option :value="1" label="机构名称"></el-option>
						<el-option :value="2" label="姓名"></el-option>
					</el-select>
					<el-button slot="append" @click="getList" icon="el-icon-search"></el-button>
				</el-input>
			</el-form-item>

			<el-form-item label="所属行业">
				<el-select value-key="id" placeholder="请选择" v-model="type" clearable filterable class="wd200">
					<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="启用状态">
				<el-select v-model="isUse" clearable style="width:200px;">
					<el-option :value="1" label="启用">启用</el-option>
					<el-option :value="3" label="启用">禁用</el-option>
				</el-select>
			</el-form-item>

			<el-form-item>
				<el-button @click="getList()" type="primary">搜索</el-button>
				<el-button @click="reset()" type="reset">重置</el-button>
			</el-form-item>
		</el-form>
		<div class="search-list">
			<el-button type="primary" @click="add">添加</el-button>
		<!-- 	<el-button type="primary" :disabled="dataListSelections.length ? false : true"
				@click="fenpei">重置密码</el-button> -->

			<el-button type="danger" @click="deleteHandle" :disabled="dataListSelections.length ? false : true">删除
			</el-button>
		</div>
		<div class="table">
			<el-table :data="dataList" style="width: 100%" v-loading="dataListLoading"
				@selection-change="selectionChangeHandle">
				<el-table-column type="selection" width="50"></el-table-column>
				<el-table-column min-width="100" label="机构名称">
					<template slot-scope="scope">
						<el-button type="text" @click="gotoInfo(scope.row)">{{scope.row.fullName}}</el-button>
					</template>

				</el-table-column>


				<el-table-column min-width="100" label="所属行业">
					<template slot-scope="scope">
						<div style="padding: 11px;">{{scope.row.industryTypeName}}</div>
					</template>
				</el-table-column>
				<el-table-column min-width="160" prop="linkName" label="联系人姓名"></el-table-column>
				<el-table-column min-width="120" prop="linkTel" label="联系人手机号"></el-table-column>

				<el-table-column min-width="100" label="启用状态">
					<template slot-scope="scope">


						<div v-if="scope.row.status&&scope.row.status==3">禁用</div>
						<div v-else-if="scope.row.status&&scope.row.status==1">启用</div>
						<div v-else>--</div>
					</template>

				</el-table-column>
				<el-table-column min-width="150" prop="createTime" label="添加时间"></el-table-column>


				<el-table-column fixed="right" label="操作" header-align="right" align="right">
					<template slot-scope="scope">
						<el-button type="text" @click="goEdit(scope.row)">编辑</el-button>
							<el-button type="text" @click="resetPwd(scope.row)">重置密码</el-button>
						
						<el-button v-if="scope.row.deviceStatus&&scope.row.deviceStatus==2" type="text"
							@click="changeStatus(scope.row,1)">禁用</el-button>
						<el-button v-else type="text" @click="changeStatus(scope.row,3)">启用</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
				:page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
				layout="total, sizes, prev, pager, next, jumper"></el-pagination>
		</div>

	</div>
</template>
<script>
	import Qs from "qs";
	import moment from "moment";
	export default {
		name: "label",
		data() {
			return {
				belongsId: "",
				belongsId2: "",
				search: "",
				type: "",
				select: 1,
				isUse: '',
				taskId: '',
				status: "",
				intParam2: "",
				deptList: [],
				intParam3: "",
				dialogVisible: false,
				dataList: [],
				dataListLoading: true, //列表loading
				dataListSelections: [], //选中的列
				pageNo: 1,
				pageSize: 10,
				totalPage: 0, //总页数
				dateRangeTime: [],
				teamsManage: [],
				typeList: [

				],
				visible: false,
				form: {

				},
				dataForm: {
					totalScore: '',
					scoreAnalysis: '',
					totalResult: '',
					scoreAdvice: '',
				},
				formList: '',
				dataRules: {
					title: [{
						required: true,
						message: "请输入任务主题",
						trigger: "blur"
					}],
				},
				edit: false,
				flag: true, //防止重复点击
				visible2: false,
				dataList2: [],
				dataListLoading2: true, //列表loading
				dataListSelections2: [], //选中的列
				pageNo2: 1,
				pageSize2: 10,
				totalPage2: 0, //总页数
			};
		},
		async mounted() {
	await this.getTypeList();
			await this.getList();
		
			await this.getDeptList()
		},
		methods: {
			fenpei() {
				this.belongsId2 = ''
				this.visible = true
			},
			bindDevice() {

				let that = this;
				let ids = '';

				ids = that.dataListSelections.map((item) => {
					return item.id;
				});
				ids = ids.join(",");

				that
					.$confirm(`确定进行分配操作?`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {

						that
							.$httpAes({
								url: this.$httpAes.adornUrl("/deviceInfo/bind"),
								method: "post",
								data: {
									stringParam1: ids,
									stringParam2: this.belongsId2
								},
								contentType: 'json',
								headers: {
									'Content-Type': 'application/json',

								},
							})
							.then(({
								data
							}) => {
								if (data.status) {
									that.$message({
										message: data.msg,
										type: "success",
										duration: 1500,
										onClose: () => {
											that.getList();
											that.visible = false
										},
									});
								} else {
									that.$message.error(data.msg);
								}
							});



					})
					.catch(() => {});
			},
			getDeptList() {
				let user = JSON.parse(sessionStorage.getItem("userInfo"))

				this.$httpAes({
					url: this.$httpAes.adornUrl('dept/list'),
					method: 'post',
					data: {

						intParam1: 1,
						pageNo: 1,
						pageSize: 99999
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},

				}).then(({
					data
				}) => {
					this.deptList = data.data


				})
			},
			async showScore(itemObj) {
				this.dataForm.totalScore = itemObj.totalScore
				this.taskId = itemObj.id
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/evaluationPackage/info"),
					method: "post",
					data: {
						stringParam1: itemObj.packageId
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					this.form = res.data;

					if (res.data.packageRule && res.data.packageRule.length > 0) {
						this.formList = JSON.parse(res.data.packageRule)
						console.log(this.formList)
						if (itemObj.totalResult && itemObj.totalResult.length > 0) {
							this.dataForm.totalResult = itemObj.totalResult
							this.dataForm.scoreAnalysis = itemObj.scoreAnalysis
							this.dataForm.scoreAdvice = itemObj.scoreAdvice
						} else {
							for (let item of this.formList) {
								if (this.dataForm.totalScore >= item.score1 && this.dataForm.totalScore <= item
									.score2) {

									this.dataForm.totalResult = item.cpjg
									this.dataForm.scoreAnalysis = item.jgfx
									this.dataForm.scoreAdvice = item.tzjy
								}
							}
						}

					}

					this.dialogVisible = true


				}
				this.dialogVisible = true
			},
			resetScore(val) {

				if (parseInt(this.dataForm.totalScore) < parseInt(this.formList[0].score1) || parseInt(this.dataForm
						.totalScore) > parseInt(this.formList[this.formList.length - 1].score2)) {
					this.$message.error('请正确填写评分')
					this.dataForm.totalScore = ''
					return
				}
				for (let item of this.formList) {
					if (parseInt(this.dataForm.totalScore) >= parseInt(item.score1) && parseInt(this.dataForm
							.totalScore) <= parseInt(item.score2)) {

						this.dataForm.totalResult = item.cpjg
						this.dataForm.scoreAnalysis = item.jgfx
						this.dataForm.scoreAdvice = item.tzjy
					}
				}
			},
			async getList() {
				let stringParam1 = ''
				let stringParam2 = ''
				let stringParam3 = ''
				let stringParam6 = ''
				let stringParam7 = ''
				let intParam1 = ''

				if (this.select == 2) {
					stringParam1 = this.search
				}
				if (this.select == 1) {
					stringParam2 = this.search
				}



				let params = {

					stringParam1: stringParam1,
					stringParam2: stringParam2,
					intParam1: this.type,
					intParam2: 1,
					intParam3: this.isUse,

					// stringParam6: stringParam6,
					// stringParam7: stringParam7,

					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};

				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/dept/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					res.data.forEach((item) => {

						item.createTime = moment(new Date(item.createTime)).format(
							"YYYY-MM-DD HH:mm:ss"
						)
					})
					this.dataList = res.data;
					for (let item of 	this.dataList) {
						for (let dict of this.typeList) {
							if(item.industryType == dict.value){
								item.industryTypeName  = dict.name
							}
						}
					}
					this.totalPage = res.totalCount;
					console.log(this.dataList)

				}
				this.dataListLoading = false;
			},
			goEdit(item) {
				this.$router.push({
					name: "deptManageUpdate",
					query: {
						id: item.id
					},
				});
			},
		
			async resetPwd(item ) {
		 
		 let that = this;
	 
		 
		 that
		 	.$confirm(`确定进行重置密码操作?`, "提示", {
		 		confirmButtonText: "确定",
		 		cancelButtonText: "取消",
		 		type: "warning",
		 	})
		 	.then(() => {
		 
		 let obj = {
		 	stringParam1:item.linkUserId
		 }
		 that
		 	.$httpAes({
		 		url: this.$httpAes.adornUrl("/user/resetpswd"),
		 		method: "post",
		 		data: obj,
		 		contentType: 'json',
		 		headers: {
		 			'Content-Type': 'application/json',
		 
		 		},
		 	})
		 	.then(({
		 		data
		 	}) => {
		 		if (data.status) {
		 			that.$message({
		 				message: data.msg,
		 				type: "success",
		 				duration: 1500,
		 				onClose: () => {
		 					//that.getList();
		 				},
		 			});
		 		} else {
		 			that.$message.error(data.msg);
		 		}
		 	});
		 
		 	 
			})
			
			},
		 
			async changeStatus(item, val) {
				item.status = val
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						"/dept/update"
					),
					method: "post",
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
					data: item,
				});
				console.log(res);
				if (res.status) {
					this.$message.success(res.msg);
					this.getList()

				}

			},

			isSameDay(date1, date2) {
				return (
					date1.getFullYear() === date2.getFullYear() && // 年份相等
					date1.getMonth() === date2.getMonth() && // 月份相等
					date1.getDate() === date2.getDate() // 日期相等
				);
			},
			gotoInfo(item) {
				this.$router.push({
					name: "deptManageInfo",
					query: {
						id: item.id
					},
				});

			},
			add() {

				this.$router.push({
					name: "deptManageAdd",
				});



			},
			cancel() {
				this.$refs["form"].resetFields();
				this.visible = false;
				this.form = {
					title: "",
				};
			},
			gotoDetail(item) {
				sessionStorage.evaluationRecDetail = JSON.stringify(item)
				this.$router.push({
					name: "evaluationRecDetail",
					query: {
						id: item.id
					},
				});
			},
			async dataFormSubmit(status) {
				if (this.dataForm.totalScore == '' || this.dataForm.totalResult == '' || this.dataForm.scoreAnalysis ==
					'' || this.dataForm.scoreAdvice == '') {
					this.$message.error('请填写完所有评分项')
					return
				}

				let obj = {
					id: this.taskId,
					totalScore: this.dataForm.totalScore,
					totalResult: this.dataForm.totalResult,
					scoreAnalysis: this.dataForm.scoreAnalysis,
					scoreAdvice: this.dataForm.scoreAdvice,
					publishStatus: status,
					judgeUserId: JSON.parse(sessionStorage.userInfo).id
				}
				if (status == 1) {
					obj.publishTime = moment(new Date()).format(
						"YYYY-MM-DD HH:mm:ss"
					)
					obj.resultTime = moment(new Date()).format(
						"YYYY-MM-DD HH:mm:ss"
					)
				}
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						"/diagnosisTask/update"
					),
					method: "post",
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
					data: obj,
				});
				console.log(res);
				if (res.status) {
					this.$message({
						message: res.msg,
						type: "success",
						duration: 1500,
						onClose: () => {
							this.dialogVisible = false
							this.getList();
						},
					});


				}
			},

			async getTypeList() {
				let params = {
					pageSize: 9999,
					pageNo: 1,
					stringParam1: '行业'
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("dict/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				console.log(res, '设备型号')
				this.typeList = res.data;

			},

			async deleteHandle() {
				let that = this;
				let ids = '';

				ids = that.dataListSelections.map((item) => {
					return item.id;
				});
				ids = ids.join(",");

				that
					.$confirm(`确定进行删除操作?`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {

						that
							.$httpAes({
								url: this.$httpAes.adornUrl("/dept/delete"),
								method: "post",
								data: {
									stringParam1: ids
								},
								contentType: 'json',
								headers: {
									'Content-Type': 'application/json',

								},
							})
							.then(({
								data
							}) => {
								if (data.status) {
									that.$message({
										message: data.msg,
										type: "success",
										duration: 1500,
										onClose: () => {
											that.getList();
										},
									});
								} else {
									that.$message.error(data.msg);
								}
							});



					})
					.catch(() => {});
			},
			reset() {

				this.type = ''
				this.isUse = ''
				this.intParam2 = ''
				this.intParam3 = ''
				this.search = ''
				this.getList();
			},
			// 每页数
			sizeChangeHandle(val) {
				this.pageSize = val;
				this.pageNo = 1;
				this.getList();
			},
			// 当前页
			currentChangeHandle(val) {
				this.pageNo = val;
				this.getList();
			},
			// 多选
			selectionChangeHandle(val) {
				this.dataListSelections = val;
			},
			// 显示下载列表
			downloadData(row) {
				this.visible2 = true;
				this.getDataList(row.id);
			},
			// 下载内容
			async getDataList(id) {
				let params = {
					pageSize: this.pageSize2,
					pageNo: this.pageNo2,
					retrievalId: id,
				};
				const {
					data: res
				} = await this.$http({
					url: this.$http.adornUrl("/mbglRetrieveRecords/list"),
					method: "get",
					params: params,
				});
				if (res.status) {
					this.dataList2 = res.data;
					this.totalPage2 = res.totalCount;
				}
				this.dataListLoading2 = false;
			},
			// 每页数
			sizeChangeHandle2(val) {
				this.pageSize2 = val;
				this.pageNo2 = 1;
				this.getDataList();
			},
			// 当前页
			currentChangeHandle2(val) {
				this.pageNo2 = val;
				this.getDataList();
			},
			// 添加检索
			addQuestionSearch(row) {
				let params = {
					retrievalId: row.id
				};
				this.$http({
					url: this.$http.adornUrl("/mbglRetrieveRecords/add"),
					method: "post",
					data: Qs.stringify(params),
				}).then(({
					data
				}) => {
					if (data.status) {
						this.updateStatus(row.id);
					}
				});
			},
			updateStatus(id) {
				let params = {
					ids: id,
					status: 3
				};
				this.$http({
					url: this.$http.adornUrl("/mbglLabelIndex/updateStatusByIds"),
					method: "post",
					data: Qs.stringify(params),
				}).then(({
					data
				}) => {
					if (data.status) {
						this.$message({
							message: "重新检索成功",
							type: "success",
							duration: 1500,
							onClose: () => {
								this.$router.push({
									name: "caseSearch",
								});
							},
						});
					}
				});
			},
			// 下载
			downloadUrl(row) {
				var that = this;
				let link = document.createElement("a");
				link.href = row.url;
				link.click();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.el-select .el-input {
		width: 130px;
	}

	.top {
		display: flex;

		.left {
			width: 50%;
		}

		.right {
			flex: 1;
		}
	}

	.scoreContent {
		font-size: 14px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 22px;
		width: 100%;
		height: 54px;
		background: #F8F3FF;
		box-sizing: border-box;
		padding: 16px;
		margin-bottom: 24px;
	}
</style>